/* General styles */
body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.farm-summary {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.farm-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

.farm-details, .documents {
  flex: 1;
}

.page-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;  /* Center the title */
}

.created-by {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  text-align: center;  /* Center the author name */
}

.section-title {
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
}

.details, .farm-info, .document-item {
  font-size: 16px;
  color: #444;
  margin-bottom: 10px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .farm-summary {
    flex-direction: column;
  }

  .farm-details, .documents {
    width: 100%;
  }
}

/* Additional styling for aesthetics */
.farm-info, .document-item {
  padding: 8px 12px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
